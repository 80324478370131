import { render, staticRenderFns } from "./WashSlider.vue?vue&type=template&id=8deddb80&scoped=true&"
import script from "./WashSlider.vue?vue&type=script&lang=js&"
export * from "./WashSlider.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8deddb80",
  null
  
)

export default component.exports